import {
  AdminsNavIcon,
  BillNavIcon,
  CouponBagNavIcon,
  DashboardIcon,
  General2Icon,
  General3Icon,
  GeneralIcon,
  NavLinkProps,
  ReportIcon,
  useAuthContext,
} from "suregifts-component-lib";
import { TwoPeople } from "../../../../components/icons/TwoPeople";
import { RolesEnum } from "../../../../services/auth.api";
export const useGetSuperAdminLinks = () => {
  const { user } = useAuthContext();
  const links: NavLinkProps[] = [
    {
      to: "/super-admin/dashboard",
      icon: DashboardIcon,
      title: "Dashboard",
    },
    {
      to: "/account/vouchers",
      icon: CouponBagNavIcon,
      title: "Vouchers",
    },
    {
      to: "/merchant-backend/voucher-operations",
      icon: ReportIcon,
      title: "Voucher Operations",
    },
    {
      to: "/account/payments",
      icon: General3Icon,
      title: "Payments",
    },

    {
      to: "/account/redemptions",
      icon: General3Icon,
      title: "Redemptions",
    },
    {
      to: "/account/activations",
      icon: General3Icon,
      title: "Activations",
    },

    {
      to: "/customer-support/users",
      icon: TwoPeople,
      title: "Customers",
    },
    {
      to: "/merchant-backend/merchants",
      icon: GeneralIcon,
      title: "Merchants",
    },

    {
      to: "/customer-support/orders",
      icon: General2Icon,
      title: "Orders",
    },
    {
      to: "/account/restore-income",
      icon: General3Icon,
      title: "Exp. Voucher Income",
    },
    {
      to: "/super-admin/bills-payments",
      icon: BillNavIcon,
      title: "Bills Payments",
    },

    {
      to: "/super-admin/bill-categories",
      icon: BillNavIcon,
      title: "Utility & Bills",
    },
  ];
  if (user?.role === RolesEnum.SUPER_ADMIN) {
    links.push({
      to: "/super-admin/admins",
      icon: AdminsNavIcon,
      title: "Admins",
    });
  }
  return links;
};
