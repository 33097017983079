import { Navigate, useLocation } from "react-router-dom";
import { useAuthContext } from "suregifts-component-lib";
import { RolesEnum } from "../services/auth.api";

export function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuthContext();
  let location = useLocation();

  if (!auth.isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (location.pathname === "/") {
    if (auth.user?.role === RolesEnum.CUSTOMER_SUPPORT || auth.user?.role === RolesEnum.SENIOR_SUPPORT)
      return <Navigate to="/customer-support/dashboard" replace />;
    else if (auth.user?.role === RolesEnum.BUSINESS_DEVELOPMENT) return <Navigate to="/merchant-backend/dashboard" replace />;
    else if (auth.user?.role === RolesEnum.STORE_PERSONNEL || auth.user?.role === RolesEnum.STORE_CONTROLLER)
      return <Navigate to="/merchant-dashboard/voucher-operations" replace />;
    else if (auth.user?.role === RolesEnum.FINANCE) return <Navigate to="/account/dashboard" replace />;
    else if (auth.user?.role === RolesEnum.SUPER_ADMIN || auth.user?.role === RolesEnum.ADMIN) return <Navigate to="/super-admin/dashboard" replace />;
  }

  return children;
}
