import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { YupShape, FormInput, FormButton } from "suregifts-component-lib";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { ChangePasswordModel, useChangePassword } from "../../services/auth.api";

const schema = yup
  .object<YupShape<ChangePasswordModel>>({
    newPassword: yup.string().required(),
    currentPassword: yup.string().required(),
    confirmPassword: yup.string().required(),
  })
  .required();
export const ChangePasswordSection = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<ChangePasswordModel>({
    resolver: yupResolver(schema),
  });
  const { mutate: changePassword, isLoading } = useChangePassword();
  const submitForm = (model) => {
    changePassword(model, {
      onSuccess: () => {
        toast.success("Password changed successfully.");
        reset();
      },
    });
  };
  return (
    <Stack spacing={2} component="form" onSubmit={handleSubmit(submitForm)}>
      <Stack direction={"row"} spacing={2}>
        <FormInput required type="password" label="Current Password" {...register("currentPassword")} helperText={errors.currentPassword?.message} />
        <div style={{ width: "100%" }}></div>
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <FormInput required type="password" label="New Password" {...register("newPassword")} helperText={errors.newPassword?.message} />
        <FormInput required type="password" label="Confirm Password" {...register("confirmPassword")} helperText={errors.confirmPassword?.message} />
      </Stack>
      <div style={{ display: "flex", flexDirection: "row-reverse", width: "100%" }}>
        <FormButton type="submit" label="Change Password" disabled={!isValid} loading={isLoading} />
      </div>
    </Stack>
  );
};
