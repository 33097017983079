import { Box, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { FormInput, FormButton, useAuthContext, ResetPasswordModel, useDocumentTitle, YupShape } from "suregifts-component-lib";
import { useResetPassword } from "../../../services/auth.api";
import { toast } from "react-toastify";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
const schema = yup
  .object<YupShape<ResetPasswordModel>>({
    email: yup.string().required(),
  })
  .required();
export const ForgotPasswordForm = () => {
  useDocumentTitle("Reset Password");
  const navigate = useNavigate();
  const auth = useAuthContext();
  const { mutate: resetPassword, isLoading } = useResetPassword();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ResetPasswordModel>({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(schema),
  });

  const submitForm = (data: ResetPasswordModel) => {
    resetPassword(data, {
      onSuccess: () => {
        toast.success("Your password has been successfully reset. Please use your new password to log in.");
        navigate("/login");
      },
    });
  };
  return (
    <Box sx={{ p: "2.5rem", position: "relative" }} component={"form"} onSubmit={handleSubmit(submitForm)}>
      <Stack spacing={3}>
        <Stack spacing={2}>
          <FormInput
            label="Email Address"
            placeholder="Enter email address"
            {...register("email", { required: { value: true, message: "Email is required" } })}
            helperText={errors.email?.message}
          />
        </Stack>
        <FormButton loading={isLoading} disabled={!isValid} fullWidth type="submit" label="Reset Password" color="secondary" />
        <Box
          component={Link}
          to={"/login"}
          sx={{
            color: "inherit",
            textDecoration: "none",
            textAlign: "center",
          }}
        >
          Login
        </Box>
      </Stack>
    </Box>
  );
};
