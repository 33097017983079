import { TableHead, TableRow, TableCell, IconButton } from "@mui/material";
import { MainTable, OrderStatusLabel, Pagination, TableBody, dateTime, useAuthContext, useDocumentTitle, useFilter, useNotificationContext } from "suregifts-component-lib";
import { FilterSection } from "./FilterSection";
import { fixedCellStyle } from "../../../helpers/cssHelper";
import { UtilityFilter, useGetUtilities, useRefundBillPayment } from "../../../services/utility";
import { TopBottomLabel } from "../../../components/molecules/TopBottomLabel";
import ReplyIcon from "@mui/icons-material/Reply";
import { useQueryClient } from "react-query";
import { useState } from "react";

function BillsPaymentScreen() {
  const [filter, setFilter] = useFilter<UtilityFilter>({ page: 1, search: "" });
  const { data, isFetching: isLoading } = useGetUtilities(filter);

  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };

  useDocumentTitle("Bills Payments");
  const queryClient = useQueryClient();
  const { mutate: refundOrder, isLoading: isSending } = useRefundBillPayment();
  const { confirm } = useNotificationContext();
  const sendRequest = (orderId: number) => {
    confirm({
      onConfirm: () => {
        refundOrder(orderId, {
          onSuccess: () => {
            queryClient.invalidateQueries("bills-payments");
          },
        });
      },
    });
  };
  return (
    <div>
      <FilterSection filter={filter} handleFilter={handleFilter} total={data?.total} />
      <MainTable loading={isLoading || isSending} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Order No.</TableCell>
            <TableCell>Requester</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Narration</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.id}</TableCell>
              <TableCell>
                <TopBottomLabel top={`${item.firstName} ${item.lastName}`} bottom={item.email} />
              </TableCell>

              <TableCell style={fixedCellStyle}>{dateTime(item.dateCreated)}</TableCell>

              <TableCell>{item.narration}</TableCell>
              <TableCell style={fixedCellStyle}>
                <OrderStatusLabel label={item.status} status={item.statusId} />
              </TableCell>
              <TableCell>{item.subTotal}</TableCell>
              <TableCell>
                {item.canRefund && (
                  <IconButton
                    onClick={() => {
                      sendRequest(item.id);
                    }}
                  >
                    <ReplyIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination total={data?.total} page={filter.page} onChange={(page) => handleFilter({ page })} />
      </div>
    </div>
  );
}

export default BillsPaymentScreen;
