import { Box, TableRow, TableCell } from "@mui/material";
import { RecentOrderDto } from "../../services/shared";
import moment from "moment";
import {
  PageTitleWithLink,
  SelectionTable,
  SelectionTableHead,
  SelectionTableBody,
  OrderStatusLabel,
  useAuthContext,
  dateOnly,
  LoadingBox,
} from "suregifts-component-lib";
import { useNavigate } from "react-router-dom";
import { RolesEnum } from "../../services/auth.api";
type RecentOrderViewProps = {
  data?: RecentOrderDto[];
  isLoading?: boolean;
  viewAllTo?: string;
};
const RecentOrderView = ({ data, isLoading, viewAllTo }: RecentOrderViewProps) => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const handleClick = (orderId) => {
    if (user?.role === RolesEnum.CUSTOMER_SUPPORT) navigate(`/customer-support/orders/${orderId}`);
    if (user?.role === RolesEnum.BUSINESS_DEVELOPMENT) navigate(`/merchant-backend/orders/${orderId}`);
  };
  return (
    <Box>
      <PageTitleWithLink title="Recent Orders" to={viewAllTo} />
      <LoadingBox loading={isLoading} sx={{ marginTop: "1.5625rem", marginLeft: "-1.25rem", marginRight: "-1.25rem" }}>
        <SelectionTable>
          <SelectionTableHead>
            <TableRow>
              <TableCell>Order No</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Send's Email</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </SelectionTableHead>
          <SelectionTableBody>
            {data?.map((item) => (
              <TableRow key={item.id} sx={{ cursor: "pointer" }} onClick={() => handleClick(item.id)}>
                <TableCell>{item.orderNumber}</TableCell>
                <TableCell>{dateOnly(item.date)}</TableCell>
                <TableCell>
                  <OrderStatusLabel label={item.status} status={item.statusId} />
                </TableCell>
                <TableCell>{item.senderEmail}</TableCell>
                <TableCell>{item.amount}</TableCell>
              </TableRow>
            ))}
          </SelectionTableBody>
        </SelectionTable>
      </LoadingBox>
    </Box>
  );
};

export default RecentOrderView;
