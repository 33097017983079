import { Box, TableCell, TableHead, TableRow } from "@mui/material";
import React, { useState } from "react";
import {
  SearchContainer,
  PageHeaderTitle,
  MainTable,
  TableBody,
  dateTime,
  usePagination,
  formatNumber,
  Pagination,
  FilterDropdown,
  SearchButton,
} from "suregifts-component-lib";
import { useExportRestoreIncome, useGetRestoreIncome } from "../../../services/vouchers";
import { fixedCellStyle } from "../../../helpers/cssHelper";

function RestoreIncomeScreen() {
  const [filter, setFilter] = useState({ year: undefined, page: 1 });
  const { data: income, isFetching } = useGetRestoreIncome(filter.year);
  const { mutate: exportIncome, isLoading: exporting } = useExportRestoreIncome(filter.year);
  const { data, total } = usePagination(filter.page, income);
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  return (
    <div>
      <SearchContainer sx={{ marginTop: "40px" }}>
        <PageHeaderTitle title={"Voucher Income"} />
        <div style={{ flexGrow: 1 }}></div>
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <FilterDropdown
            value={filter.year}
            display="Year"
            options={[
              { text: "2024", value: 2024 },
              { text: "2023", value: 2023 },
              { text: "2022", value: 2022 },
              { text: "2021", value: 2021 },
              { text: "2020", value: 2020 },
              { text: "2019", value: 2019 },
              { text: "2018", value: 2018 },
              { text: "2017", value: 2017 },
              { text: "2016", value: 2016 },
              { text: "2015", value: 2015 },
            ]}
            onChange={(index, value) => handleFilter({ year: value, page: 1 })}
          />
          <SearchButton
            disabled={exporting}
            label="Export"
            onClick={() => {
              exportIncome({});
            }}
          />
        </Box>
      </SearchContainer>
      <MainTable loading={isFetching} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Month/Year</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item) => (
            <TableRow key={`${item.year}-${item.month}`}>
              <TableCell style={fixedCellStyle}>{`${item.month}/${item.year}`}</TableCell>
              <TableCell>{formatNumber(item.amount)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination page={filter.page} total={total} onChange={(page) => handleFilter({ page })} />
      </div>
    </div>
  );
}

export default RestoreIncomeScreen;
