import { TableHead, TableRow, TableCell, IconButton } from "@mui/material";
import {
  BooleanLabel,
  MainTable,
  OptionNaming,
  Pagination,
  SelectPopover,
  TableBody,
  UserStatusLabel,
  UserStatusType,
  dateOnly,
  dateTime,
  useAuthContext,
} from "suregifts-component-lib";
import { UserData, useChangeUserStatus, useGetUsers, useVerifyUser } from "../../../../services/user-security";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { fixedCellStyle } from "../../../../helpers/cssHelper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { RolesEnum } from "../../../../services/auth.api";
function TableView({ filter, handleFilter }) {
  const { data: users, isFetching, refetch } = useGetUsers(filter);
  const navigate = useNavigate();
  const { mutate: verifyUser, isLoading } = useVerifyUser();
  const { mutate: changeStatus } = useChangeUserStatus();

  const { user } = useAuthContext();
  const userOptions = (item: UserData) => {
    const options: OptionNaming[] = [];
    if (!item.isVerified && (user?.role === RolesEnum.ADMIN || user?.role == RolesEnum.SUPER_ADMIN || user?.role === RolesEnum.SENIOR_SUPPORT)) {
      options.push({ text: "Verify Email/Phone", value: 1 });
    }
    if (item.statusId !== UserStatusType.BLACKLISTED) {
      options.push({ text: item.statusId === UserStatusType.ACTIVE ? "Block User" : "Unblock User", value: 2 });
    }
    return options;
  };
  return (
    <>
      <MainTable
        loading={isFetching}
        sx={{
          marginTop: "40px",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Verified</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Date Registered</TableCell>

            <TableCell>Options</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users?.items.map((item) => (
            <TableRow key={item.id}>
              <TableCell>
                {item.firstName} {item.lastName}
              </TableCell>
              <TableCell style={{ wordBreak: "break-word" }}>{item.email}</TableCell>
              <TableCell>
                <BooleanLabel value={item.isVerified} type="yesno" />
              </TableCell>
              <TableCell>
                <UserStatusLabel status={item.status} statusId={item.statusId} />
              </TableCell>
              <TableCell style={fixedCellStyle}>{dateOnly(item.registeredDate)}</TableCell>
              <TableCell style={fixedCellStyle}>
                <IconButton onClick={() => navigate(`${item.id}`)}>
                  <VisibilityIcon />
                </IconButton>
                <SelectPopover
                  onChange={(index, value) => {
                    if (value == 1) {
                      verifyUser(item.id, {
                        onSuccess: () => {
                          toast.success("User verified successfully.");
                          refetch();
                        },
                      });
                    } else if (value == 2) {
                      const model = {
                        statusId: item.statusId === UserStatusType.ACTIVE ? UserStatusType.BLOCKED : UserStatusType.ACTIVE,
                        userId: item.id,
                      };
                      changeStatus(model, {
                        onSuccess: () => {
                          toast.success("User status changed successfully.");
                          refetch();
                        },
                      });
                    }
                  }}
                  options={userOptions(item)}
                  render={(handleClick) => (
                    <IconButton onClick={handleClick}>
                      <MoreHorizIcon />
                    </IconButton>
                  )}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination
          page={filter.page}
          total={users?.total}
          onChange={(page) => {
            handleFilter({ page });
          }}
        />
      </div>
    </>
  );
}
export default TableView;
