import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { DialogTitle, FormButton } from "suregifts-component-lib";
import { CreateEditMerchantModel, useCreateMerchant } from "../../../../services/merchants";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { MerchantAddEditForm } from "./MerchantAddEditForm";
import { merchantSchema } from "./merchantSchema";
import { useNavigate } from "react-router-dom";

function AddMerchantModal() {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/merchant-backend/merchants");
  };
  const { mutate: create, isLoading } = useCreateMerchant();
  const form = useForm<CreateEditMerchantModel>({
    defaultValues: {
      categories: [],
      paymentType: "",
    },
    resolver: yupResolver(merchantSchema),
  });
  const {
    formState: { errors, isValid },
    handleSubmit,
  } = form;
  const submitForm = (data: CreateEditMerchantModel) => {
    data.redmeptionDetails = [data.redmeptionDetail];
    create(data, {
      onSuccess: () => {
        toast.success("Succesfull.");
        handleClose();
      },
    });
  };
  return (
    <FormProvider {...form}>
      <Dialog maxWidth={"md"} open>
        <DialogTitle title="Add Merchant" handleClose={handleClose} />
        <DialogContent>
          <MerchantAddEditForm />
        </DialogContent>
        <DialogActions>
          <FormButton disabled={!isValid} loading={isLoading} onClick={handleSubmit(submitForm)} label="Submit" />
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}

export default AddMerchantModal;
