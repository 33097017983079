import { Box, Stack, Divider, Typography, SxProps } from "@mui/material";
import moment from "moment";
import { FormButton, PageHeaderTitle, TitleDetailView, UserStatusLabel, useAuthContext } from "suregifts-component-lib";
import { UserInfoData } from "../../../../services/user-security";

import numeral from "numeral";
import { RolesEnum } from "../../../../services/auth.api";
import { useQueryClient } from "react-query";
import { useState } from "react";
import CreditUserModal from "./modals/CreditUserModal";
import DebitUserModal from "./modals/DebitUserModal";

type UserInfoSectionProps = {
  data?: UserInfoData;
};

export function UserInfoView({ data }: UserInfoSectionProps) {
  const { user } = useAuthContext();
  const [action, setAction] = useState<"credit" | "debit" | undefined>();
  const queryClient = useQueryClient();
  const handleClose = () => {
    queryClient.invalidateQueries("transactions");
    queryClient.invalidateQueries("user-byId");
    queryClient.invalidateQueries("orders");
    setAction(undefined);
  };
  if (!data) return <div></div>;
  return (
    <div>
      <PageHeaderTitle title="User Details" />
      <Box p="1.85rem" sx={{ mt: "1.25rem", bgcolor: "primary.main", boxShadow: 2, borderRadius: "1.25rem" }}>
        <Stack spacing={"1.85rem"}>
          <Stack direction={"row"} spacing={3}>
            <TitleDetailView title="Registered Date" detail={moment(data?.registeredDate).format("DD.MM.YYYY")} />
            <TitleDetailView align="right" title="Name" detail={`${data?.firstName} ${data?.lastName}`} />
          </Stack>
          <Box>
            <TitleDetailView title="Email" detail={data?.email} />
          </Box>
          <Stack spacing={3} direction={"row"}>
            <TitleDetailView title="Phone Number" detail={data?.phoneNumber} />
            <TitleDetailView align="right" title="Status" detail={<UserStatusLabel status={data.status} statusId={data.statusId} />} />
          </Stack>
        </Stack>
        <Divider sx={{ my: "2.5rem" }} />
        <Stack spacing={5}>
          <Box
            display={"flex"}
            justifyContent="space-between"
            alignItems={"center"}
            sx={{
              flexWrap: "wrap",
              gap: "20px",
            }}
          >
            <Box display="flex" gap="0.9375rem">
              <Box component={"img"} src="/assets/images/wallet.png" />
              <div>
                <Typography fontSize={"0.875rem"} color="text.secondary">
                  Wallet Balance:
                </Typography>
                <Typography fontSize={"1.5rem"} fontWeight={700}>
                  {user?.currency}
                  {numeral(data?.walletBalance).format("0,0.00")}
                </Typography>
              </div>
            </Box>
          </Box>
          <Stack direction={"row"} spacing={2}>
            {(user?.role === RolesEnum.ADMIN || user?.role === RolesEnum.SUPER_ADMIN) && (
              <>
                <FormButton sx={buttonStyles} label={"Fund Wallet"} color="success" onClick={() => setAction("credit")} />
                <FormButton sx={buttonStyles} label={"Debit Wallet"} color="primary" onClick={() => setAction("debit")} />
              </>
            )}
          </Stack>
        </Stack>
      </Box>
      {action === "credit" && <CreditUserModal handleClose={handleClose} />}
      {action === "debit" && <DebitUserModal handleClose={handleClose} />}
    </div>
  );
}

const buttonStyles: SxProps = { py: "1.25rem", paddingX: "2px", width: "100%" };
