import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { DialogTitle, FormButton } from "suregifts-component-lib";
import { CreateEditMerchantModel, MerchantData, MerchantInfoData, useEditMerchant } from "../../../../services/merchants";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { MerchantAddEditForm } from "./MerchantAddEditForm";
import { merchantSchema } from "./merchantSchema";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

function EditMerchantModal() {
  const navigate = useNavigate();
  const handleClose = () => {
    sessionStorage.removeItem("MERCHANT_INFO");
    navigate("..");
  };
  const [merchant] = useState<MerchantInfoData>(() => {
    var data = sessionStorage.getItem("MERCHANT_INFO");
    if (!data) {
      handleClose();
      return {} as MerchantInfoData;
    }

    return JSON.parse(data) as MerchantInfoData;
  });
  useEffect(() => {
    if (!sessionStorage.getItem("MERCHANT_INFO")) {
      handleClose();
    }
  }, []);

  const { mutate: create, isLoading } = useEditMerchant();
  const form = useForm<CreateEditMerchantModel>({
    defaultValues: {
      ...merchant,
      superMerchantId: merchant.superMerchantId ?? "",
      categories: merchant?.categories?.map((x) => x.id),
      paymentType: merchant?.paymentTypeId?.toString(),
      redmeptionDetail: merchant?.redmeptionDetails ? merchant.redmeptionDetails[0] : undefined,
    },
    resolver: yupResolver(merchantSchema),
  });
  const {
    formState: { isValid },
    handleSubmit,
  } = form;
  const submitForm = (data: CreateEditMerchantModel) => {
    data.redmeptionDetails = [data.redmeptionDetail];
    create(data, {
      onSuccess: () => {
        toast.success("Succesfull.");
        handleClose();
      },
    });
  };
  if (!merchant) return <></>;
  return (
    <FormProvider {...form}>
      <Dialog maxWidth={"md"} open>
        <DialogTitle title="Edit Merchant" handleClose={handleClose} />
        <DialogContent>
          <MerchantAddEditForm />
        </DialogContent>
        <DialogActions>
          <FormButton disabled={!isValid} loading={isLoading} onClick={handleSubmit(submitForm)} label="Submit" />
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}

export default EditMerchantModal;
