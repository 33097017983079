import * as yup from "yup";
import { PageFilter, VoucherOperationType, VoucherStatusType, VoucherType, YupShape } from "suregifts-component-lib";

export interface ActivateVoucherModel {
  serialNumber: string;
  storeId: string;
  merchantId: string;
  value: string;
}
export interface RedeemVoucherModel {
  storeId: string;
  merchantId: string;
  amountToUse: string;
  pin: string;
  voucherCode: string;
}

export interface VoucherInfoData {
  operations: VoucherOperationData[];
  serialNumber: string;
  email: string;
  name: string;
  requesterEmail: string;
  typeId: VoucherType;
  type: string;
  merchantName: string;
  voucherCode: string;
  value: string;
  expiryDate: Date;
  balance: string;
  statusId: VoucherStatusType;
  status: string;
  giftId: string;
  imageUrl: string;
  countries: string;
  currencySymbol: string;
  currency: string;
  isSuregifts: boolean;
}
export interface ActivationRedemptionData {
  voucherCode: string;
  voucherSerialNumber: string;
  amount: number;
  transactionNumber: string;
  externalReference: string;
  storeName: string;
  storeId: number;
  dateTime: string;
  typeId: number;
  type: string;
  channelId: number;
  channel: string;
  merchantId: number;
  merchantName: string;
  name: string;
  email: string;
  phone: string;
}

export interface VoucherOperationData {
  narration: string;
  date: string;
  amount: string;
}
export const redeemSchema = yup
  .object<YupShape<RedeemVoucherModel>>({
    voucherCode: yup.string().required(),
    pin: yup.string().required(),
    amountToUse: yup.string().required("Amount useds is required."),

    storeId: yup.string().required(),
  })
  .required();
export const activationSchema = yup
  .object<YupShape<ActivateVoucherModel>>({
    serialNumber: yup.string().required(),
    value: yup.string().required("Amount is required."),

    // merchantId: yup.string().required(),
    storeId: yup.string().required(),
  })
  .required();
export interface VoucherTransactionData {
  code: string;
  serialNumber: string;
  amount: string;
  id: string;
  externalReference: string;
  pointOfUsage: string;
  giftCardType: string;
  storeId: number;
  dateTime: string;
  typeId: VoucherOperationType;
  type: string;
  usageMedium: string;
  usageMediumId: number;
  merchantName: string;
  narration: string;
  merchantIncome: string;
  refundStatus: string;
  refundStatusId: number;
}

export interface VoucherTransactionFilter extends PageFilter {
  dateFrom?: Date;
  dateTo?: Date;
  merchantId?: any;
  storeId?: any;
  typeId: any;
}
