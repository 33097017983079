import { useMutation, useQuery } from "react-query";
import { Count, NameAndId, OrderStatusType, PageFilter, ServerError, TransactionType, WalletType, useAxios } from "suregifts-component-lib";

export const useGetCountries = (onSuccess?: (data: NameAndId[]) => void) => {
  const axios = useAxios();
  return useQuery<NameAndId[], ServerError>({
    queryKey: ["countries"],
    keepPreviousData: true,
    initialData: [],
    retry: false,
    queryFn: () => axios.get("/utilities/api/bills-countries", {}),
    onSuccess: onSuccess,
  });
};
export const useGetBillCategories = (countryCode?: string, onSuccess?: (data: BillCategoryData[]) => void) => {
  const axios = useAxios();
  return useQuery<BillCategoryData[], ServerError>({
    queryKey: ["bill-categories", { countryCode }],
    keepPreviousData: true,
    initialData: [],
    enabled: !!countryCode,

    queryFn: () =>
      axios.get("/utilities/api/bill-categories", {
        params: { countryCode },
      }),
    onSuccess: onSuccess,
  });
};

export const useChangeCategoryStatus = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, string>((categoryId?: string) => {
    return axios.post(`/utilities/api/bill-categories/${categoryId}/status`);
  });
};
export const useGetBillers = (categoryId: string) => {
  const axios = useAxios();
  return useQuery<BillerHouseData, ServerError>({
    queryKey: ["billers", { categoryId }],
    keepPreviousData: true,
    enabled: !!categoryId,
    queryFn: () => axios.get(`/utilities/api/${categoryId}/billers`, {}),
  });
};

export const useChangeBillerStatus = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, string>((billerId: string) => {
    return axios.post(`/utilities/api/billers/${billerId}/status`);
  });
};

export const useGetPackages = (billerId?: string) => {
  const axios = useAxios();
  return useQuery<PackageHouseData, ServerError>({
    queryKey: ["packages", { billerId }],
    keepPreviousData: true,
    enabled: !!billerId,
    queryFn: () => axios.get(`/utilities/api/${billerId}/packages`, {}),
  });
};

export const useChangePackageStatus = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, string>((packageId?: string) => {
    return axios.post(`/utilities/api/packages/${packageId}/status`);
  });
};

export interface BillCategoryData {
  id: string;
  name: string;
  description: string;
  isActive: boolean;
  providerType: string;
}
export interface BillerData {
  id: string;
  name: string;
  surcharge: number;
  surgeString: string;
  isActive: boolean;
}
export interface BillerHouseData {
  billers: BillerData[];
  categoryId: string;
  categoryName: string;
  providerTypeId: number;
  provideType: string;
}
export interface PackageData {
  id: string;
  name: string;
  isActive: boolean;
  amount: number;
  amountString: string;
}
export interface PackageHouseData {
  packages: PackageData[];
  billerName: string;
  billerId: string;
  categoryId: string;
}

export const useGetUtilities = (filter: UtilityFilter) => {
  const path = "utilities/api";
  const axios = useAxios();
  return useQuery<Count<UtilityData>, ServerError>({
    queryKey: ["bills-payments", filter],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    queryFn: () =>
      axios.get(path, {
        params: filter,
      }),
  });
};

export const useRefundBillPayment = () => {
  const axios = useAxios();
  return useMutation<unknown, ServerError, number>((orderId) => {
    return axios.post(`/utilities/api/${orderId}/refund`);
  });
};

export interface UtilityFilter extends PageFilter {
  type?: any;
}
export interface UtilityData {
  canRefund: boolean;
  id: number;
  billTypeId: TransactionType;
  billType: string;
  statusId: OrderStatusType;
  status: string;
  subTotal: number;
  narration: string;
  dateCreated: string;
  paymnetMethodId: WalletType;
  paymentMethod: string;
  email: string;
  firstName: string;
  lastName: string;
  invoiceNumber: string;
}
