import { Dialog, DialogContent, Stack } from "@mui/material";
import { DialogTitle, FormButton, FormInput, LoadingBox, useAuthContext } from "suregifts-component-lib";
import { useGetRedemptionById } from "../../../services/vouchers";
import { useState } from "react";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { useReverseRedemption } from "../../../services/vouchers";
import { RolesEnum } from "../../../services/auth.api";
import { ReverseTransactionInfo } from "./ReverseTransactionInfo";
type RedemptionInfoModalProps = {
  transactionId: any;
  handleClose: () => void;
};
function RedemptionInfoModal({ transactionId, handleClose }: RedemptionInfoModalProps) {
  const { data, isLoading } = useGetRedemptionById(transactionId);
  const queryClient = useQueryClient();
  const { user } = useAuthContext();
  const [edit, setEdit] = useState(false);
  const [amountUsed, setAmountUsed] = useState("");
  const { mutate: reverse, isLoading: sending } = useReverseRedemption();

  if (!data || isLoading) {
    return (
      <Dialog open maxWidth="xs">
        <LoadingBox loading />
      </Dialog>
    );
  }

  return (
    <Dialog open maxWidth="xs" scroll="paper">
      <DialogTitle title="Redemption Details" handleClose={handleClose} />
      <DialogContent>
        <ReverseTransactionInfo data={data} />
        {user?.role === RolesEnum.SUPER_ADMIN && !edit ? (
          <FormButton color={"success"} fullWidth label={"Update Transaction"} sx={{ marginTop: "30px" }} onClick={() => setEdit(true)} />
        ) : (
          <Stack spacing={2} sx={{ marginTop: "30px" }}>
            <FormInput isNumber label="Amount Used" value={amountUsed} onChange={(e) => setAmountUsed(e.target.value)} />
            <Stack spacing={2} direction={"row"}>
              <FormButton color={"error"} fullWidth label={"Cancel"} onClick={() => setEdit(false)} />
              <FormButton
                color={"success"}
                fullWidth
                label={"Submit"}
                onClick={() => {
                  reverse(
                    { transactionId, amountUsed },
                    {
                      onSuccess: () => {
                        toast.success("Transaction updated successfully.");
                        if (parseFloat(amountUsed) == 0) {
                          handleClose();
                        } else {
                          queryClient.invalidateQueries("redemptions-by-Id");
                          setEdit(false);
                        }
                      },
                    }
                  );
                }}
              />
            </Stack>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default RedemptionInfoModal;
